import {createContext, useEffect, useReducer} from "react"
import reducer from "./AuthReducer"

const initialState = {
    currentUser :JSON.parse(localStorage.getItem("user")) || null,
}

// un composant qui retourne un autre composant
export const AuthContext = createContext(initialState)

// créer un composant d'ordre superieur qui va retourner 
export const AuthContextProvider = ({children}) =>{
    const [state, dispatch] = useReducer(reducer, initialState)
    
    useEffect(()=>{
        localStorage.setItem("user", JSON.stringify(state.currentUser))
    }, [state.currentUser])
    
    return (
        <AuthContext.Provider value={{currentUser: state.currentUser, dispatch}}>
            {children}
        </AuthContext.Provider>
    )
}