
export default function reducer(state, action){
        switch(action.type){
            case 'LOGIN' :
                return {
                    currentUser : action.payload
                }
            case 'SIGN UP' :
                return {
                    currentUser : action.payload
                }
            case 'LOGOUT' : 
                return {
                    currentUser : null
                }
            default : 
                return state
        }
    }
