import { useState } from 'react';
import FormInput from '../../FormInput/FormInput'
import { Timestamp, collection, addDoc} from "firebase/firestore"; 
import Bouton from '../../Bouton/Bouton'
import {db} from "../../../firebase-connect"
import { useNavigate } from "react-router-dom";

export default function AddQuotes() {
    const quotesRef = collection(db, "quotes")

    const [addBy, setAddBy] = useState("")
    const [text, setText] = useState("")
    const [msgAlert, setMsgAlert] = useState("")
    const newDate = Timestamp.fromDate(new Date()).toDate()

    const navigate = useNavigate();


    const handleAddQuote = async (e)=>{
        e.preventDefault()

        if(text.length !== 0 && addBy.length !== 0){
            await addDoc(quotesRef, {
                text : text,
                addBy : addBy,
                dateAdd : newDate
            });
            navigate("/les-citations-de-francis")
        }else{
            const msgAlert = "Merci de remplir les champs. "
            setMsgAlert(msgAlert)
        }

    } 

    return (
        <div className='addQuotes'>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <h2>Ajouter une citation</h2>
                            <FormInput 
                                placeholder = "Ajouté par"
                                change={(e)=>{setAddBy(e.target.value)}}
                                
                            />
                            <textarea  
                                className='form-control mt-2' 
                                name="quote" 
                                id="quote" 
                                cols="30" 
                                rows="10" 
                                onChange={(e)=>{setText(e.target.value)}} 
                                required
                            >
                            </textarea>
                            {/* Message alert si const n'est pas vide*/}
                            {
                                msgAlert.length !== 0 && 
                                <div class="alert alert-warning my-2 py-2" role="alert">
                                    {msgAlert}
                                </div>
                            }
                            <Bouton
                                btn="btn btn-primary my-2"
                                click={handleAddQuote}
                            >Ajouter</Bouton>
                    </div>
                </div>
            </div>
        </div>
    )
}
