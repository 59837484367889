// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import{getFirestore} from "@firebase/firestore"
import{getStorage} from "@firebase/storage"
import { getAuth } from "@firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "poemes2023.firebaseapp.com",
    projectId: "poemes2023",
    storageBucket: "poemes2023.appspot.com",
    messagingSenderId: "289271168196",
    appId: "1:289271168196:web:e73d802a5fb53217281d91"
  };
  
const app = initializeApp(firebaseConfig)
// Connecter la bdd de firebase
export const db = getFirestore(app)
// Connecter le module sorage de firebase
export const storage = getStorage(app)
// Connecter module authentification de firebase
export const auth = getAuth(app)


