import React from 'react'

export default function LegalNotice() {
  return (
    <div>
      <div className="container">
        <h2>Mentions légales</h2>
        <p>Les présentes conditions générales fixent les modalités d'utilisation du service web https://francis-lepoete.fr 
          L'utilisateur reconnait avoir pris connaissance de ce document et accepté l'ensemble de ces informations, que cet usage soit fait à titre personnel ou professionnel. Si cet 
          usage est fait à titre professionnel, l'utilisateur garantit détenir les pouvoirs nécessaires pour accepter ces conditions générales au sein de son organisation. 
        </p>
        <h3>ÉDITEUR ET RESPONSABLE DE PUBLICATION </h3>
        <p>Ce site non déclaré à la CNIL est édité par Olimpiada SACAREANU. </p>
      </div>
    </div>
  )
}
