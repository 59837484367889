import { NavLink } from "react-router-dom"
import SeparateurFleur from "../../assets/img/separateur-fleur-citation.svg"

export default function Footer(){
    return(
        <footer>
            <img className="" src={SeparateurFleur} alt="Ligne separateur" />
            <NavLink to="/mentions-legales">
                mentions légales
            </NavLink>
            <NavLink to="/politique-de-confidentialite">
                politique de confidentialité
            </NavLink>
            <p className="text-center">Le contenu du site est autorisé, sous réserve que soie indiqué clairement le nom de l'auteur et de la source, par un lien vers une des pages de ce site web.
            </p>
            <p>conception du site : <a href="https://web.olimpiasacareanu.com">Olimpiada</a> <br/>
                ©2023 Chez Francis - le poète</p>
        </footer>
    )
}