import Photo1 from "../../assets/img/ill-francis-11.png"
import Photo2 from "../../assets/img/ill-francis-21.png"
import Photo3 from "../../assets/img/ill-francis-31.png"
import Photo4 from "../../assets/img/ill-francis-41.png"
import glace from "../../assets/img/verre.png"
import flower from "../../assets/img/fleur.png"
import face from "../../assets/img/face.png"
import quote from "../../assets/img/citation.png"
import { NavLink } from "react-router-dom"

export default function Accueil(){
    return(
        <>
            <div className="accueil">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 mt-5">
                        <p><i><b>Francis est mon ami, est ton ami, Francis est l'ami de tout le monde.</b> Il passe par la vie en rigolant, en dansant, en aimant… il ne doit pas attendre une merveille pour vivre, puisqu'il crée sa vie et la transforme en art. Comme dit Dominique, s'il n'existait pas, il fallait l'inventer. 
                        <br/>Si vous avez besoin de vie, d'inspiration, de vibrations, invitez-le boire une bonne bière. Peut-être il va remplacer quelques heures chez le thérapeute.
                        <br/><b>Ce site est une dédicace pour notre poète Francis…</b> sensible à la vie, à la nature et à tout ce qui se passe autour de nous, dans la société. 
                        Plongez dans son monde… 
                        </i></p>
                    </div>
                </div>
            </div>
                <div className="container nav">
                    <div className="row justify-content-center ">
                        <div className="col-lg-3 col-md-6  col-md-6">
                            <NavLink to="/collaborer-avec-francis ">
                                <img src={Photo3} alt="Francis" />
                                <img className="ill flower" src={flower} alt="Fleur" />
                                <h2>Collaborer avec Francis</h2>
                            </NavLink>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <NavLink to="/poemes">
                                <img src={Photo2} alt="Francis" />
                                <img className="ill face" src={face} alt="Silhouette" />
                                <h2>Les poemes du Francis</h2>
                            </NavLink>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <NavLink to="/">
                                <img src={Photo1} alt="Francis" />
                                <img className="ill glace" src={glace} alt="Verre" />
                                <h2>Bienvenue chez Francis</h2>
                            </NavLink>
                        </div>
                        <div className="col-lg-3 col-md-6  col-md-6">
                            <NavLink to="/les-citations-de-francis">
                                <img src={Photo4} alt="Francis" />
                                <img className="ill quote" src={quote} alt="Citation" />
                                <h2>Francis est inspiré</h2>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


