// App
import './App.css';
import * as React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom"
import Accueil from './components/Accueil/Accueil';
import Login from './components/Login/Login';
import Poemes from './components/Poemes/Poemes';
import Navbar from './components/Navbar/Navbar';
import Footer from "./components/Footer/Footer"
import { Navigate } from 'react-router-dom';
import PoemesPublic from './components/PoemesPublic/PoemesPublic';
import Collaboration from './components/Collaboration/Collaboration';
import Quotes from "./components/Quotes/Quotes"
import { AuthContext } from './components/context/AuthContext';
import SignUp from "./components/SignUp/SignUp"
import Logout from './components/Logout/Logout';
import AddPoem from './components/AddPoem/AddPoem';
import AddQuotes from './components/Quotes/AddQuotes/AddQuotes';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import LegalNotice from './components/LegalNotice/LegalNotice';

function App() {
  const {currentUser} = React.useContext(AuthContext)
  const RequireAuth = ({children})=>{
    return currentUser ? (children) : <Navigate to="/login" />
  }
  // let url = window.location
 
  return (
    
    <>
    {/* BrowserRouter Permet d'indiquer le chemin du site en l'indiquant dans basename */}
      <BrowserRouter > 
      <Navbar/>
        <Routes>
          <Route>
            <Route path="/" element={<Accueil />} />
            <Route path="/les-poemes-de-francis" element={<PoemesPublic />} />
            <Route path="/collaborer-avec-francis" element={<Collaboration />} />
            <Route path="/les-citations-de-francis" element={<Quotes />} />
            <Route path="/ajouter-citation" element={<AddQuotes />} />
            <Route path="*" element={()=>{<p>Erreur</p>}} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/mentions-legales" element={<LegalNotice />} />
            <Route path="/politique-de-confidentialite" element={<PrivacyPolicy />} />
            <Route path="/poemes"
              element={
                  <Poemes/>
              } 
            />
            <Route path="/ajouter-poeme"
              element={
                <RequireAuth>
                  <AddPoem/>
                </RequireAuth>
              } 
            />
          </Route>
        </Routes>
        <Footer/>
      </BrowserRouter>
    </>
  );
}

export default App;
