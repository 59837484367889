import React from 'react'
import { useState} from 'react';
// Connexion à la bdd
import {db, storage} from "../../firebase-connect"
// Ajouter les données à la bdd
import {collection, addDoc} from "firebase/firestore"
// Connexion à la bibilothèque media de firebase
import{ref, getDownloadURL, uploadBytesResumable} from "@firebase/storage"
// Generer du code des chiffres et lettres
import {v4} from "uuid"
// Component input form
import FormInput from '../FormInput/FormInput';
// Component bouton
import Bouton from "../Bouton/Bouton"
import { useNavigate } from 'react-router';
// Redirectionner vers un autre url


export default function AddPoem() {
    const [newTitle, setNewTitle] = useState("")
    const [newText, setNewText] = useState("")
    const [newAddBy, setNewAddBy] = useState("")
    const [newAnnee, setNewAnnee] = useState(0)
    // const [poemes, setPoemes] = useState([])
    const poemesCollectionRef = collection(db, 'poemes');
    // Image upload
    const [imageUpload, setImageUpload] = useState("")
    const [per, setPer] = useState('')
    const navigate = useNavigate()
    const dateAdd = Date().toLocaleString()
  
    // Creation d'un item dans la bdd
    const createPoeme = async()=>{
      try{
        await addDoc(poemesCollectionRef, {title : newTitle, text : newText, annee: newAnnee, addBy:newAddBy, file : imageUpload, dateAdd : dateAdd} )
        navigate('/poemes')
      }catch(e){
        alert("Merci de valider la photo selectionnée.")
      }
    }

    const uploadFile =()=>{
        // e.preventDefault()
        let newNamePhoto = imageUpload.name + v4()
        const storageRef = ref(storage, `images/${newNamePhoto}`)
        const uploadTask = uploadBytesResumable(storageRef, imageUpload);

        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on('state_changed', 
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            setPer(progress)
    
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused');
                break;
              case 'running':
                console.log('Upload is running');
                break;
              default : console.log("default")
            }
          }, 
          (error) => {
            console.log(error)
          }, 
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setImageUpload(downloadURL)
              console.log('File available at', downloadURL);
            });
          }
        );
        setImageUpload(newNamePhoto)
      }


  return (
    <>
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <h1 className='my-2'>Ajouter un poème</h1>
                    {/* Formulaire pour ajouter un poème */}
                    <FormInput 
                    required = {"required"}
                        placeholder = "Titre"
                        change = {(event)=>{
                            setNewTitle(event.target.value)
                        }}
                    />
                    <FormInput 
                        placeholder = "Poème ajouté par"
                        change = {(event)=>{
                            setNewAddBy(event.target.value)
                        }}
                    />
                    <FormInput 
                        type='number' 
                        placeholder = "Année poeme"
                        change = {(event)=>{
                            setNewAnnee(event.target.value)
                        }}
                    />
                    <div className="form-group ">
                        <textarea  
                            rows="10" 
                            cols="33"
                            className="form-control mt-4 rounded-0 border-0"
                            onChange={(event)=>{
                            setNewText(event.target.value)
                            }}
                        ></textarea>
                    </div>
                    <div className="img-add form-inline">
                      <FormInput 
                        type="file" 
                        accept="image/*" 
                        change = {(event)=>{setImageUpload(event.target.files[0])}}
                      />
                      <Bouton btn={"btn btn-success mx-1"} click={uploadFile}>Ajout photo</Bouton>
                    </div>
                    
                    <Bouton btn={"btn btn-success my-3"}  click={createPoeme}>Ajouter le poème</Bouton>
                </div>
            </div>
        </div>
    </>
  )
}
