// App
import { useState, useEffect, useContext} from 'react';
import {db, storage} from "../../firebase-connect"
import {collection, doc, deleteDoc, onSnapshot, updateDoc, query, orderBy, getDocs, where} from "firebase/firestore"
import{ref, getDownloadURL, uploadBytesResumable} from "@firebase/storage"
// Generer du code des chiffres et lettres
import {v4} from "uuid"
import FormInput from '../FormInput/FormInput';
import Bouton from "../Bouton/Bouton"
import { AuthContext } from '../context/AuthContext';
import PoemesPublic from '../PoemesPublic/PoemesPublic';

function Poemes() {
  const [newTitle, setNewTitle] = useState("")
  const [newText, setNewText] = useState("")
  const [newAddBy, setNewAddBy] = useState("")
  const [newAnnee, setNewAnnee] = useState(0)
  const [years, setYears] = useState("2023")
  const [categories, setCategories] = useState("politique")
  const [poemes, setPoemes] = useState([])
  const [values, setValues] = useState({poemeModifier : 0, form : false})
  // Collections bdd
  const poemesCollectionRef = collection(db, 'poemes');
  const yearsCollectionRef = collection(db, "years")
  const categoriesCollectionRef = collection(db, "categories")
  // Filtrer par date ASC 
  const orderAsc = query(poemesCollectionRef, orderBy('dateAdd', "desc"))
  // Variables pour le poeme à modifier, et le formulaire disparait une fois que c'est validé
  // Image upload
  const [imageUpload, setImageUpload] = useState("")
  const [per, setPer] = useState('')
  // Acceder à l'utilisateur courrant
  const {currentUser} = useContext(AuthContext)
  console.log(currentUser)

  const updatePoeme = async(id)=>{
    try{
      const poemeDoc = doc(db, "poemes", id)
      if(newTitle !== '' ){
        await updateDoc(poemeDoc, {title:newTitle})
      }
      if(newText !== ''){
        await updateDoc(poemeDoc, {text:newText})
      }
      if(newAnnee !== 0){
        await updateDoc(poemeDoc, {annee: newAnnee})
      }
      if(newAddBy !== ''){
        await updateDoc(poemeDoc, {addBy:newAddBy})
      }
      if(imageUpload !== ""){
        await updateDoc(poemeDoc, {file:imageUpload})
      }
      
      setValues({form : false})
    }catch(e){
      console.log(e)
      alert("Merci de valider la photo.")
    }
  }

  const deletePoeme = async(id)=>{
    const deletePoem = window.confirm("Voulez-vous supprimer le poème?")
    // Identifier l'id du poeme dans la bdd
    if(deletePoem) {
      const poemeDoc = doc(db, "poemes", id)
      // Supprimer le poeme identifié
      await deleteDoc(poemeDoc)
    }
  }
  
  const uploadFile =()=>{
    // e.preventDefault()
    let newNamePhoto = imageUpload.name + v4()
    const storageRef = ref(storage, `images/${newNamePhoto}`)
    const uploadTask = uploadBytesResumable(storageRef, imageUpload);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on('state_changed', 
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        setPer(progress)

        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
          default : console.log("default")
        }
      }, 
      (error) => {
        console.log(error)
      }, 
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImageUpload(downloadURL)
          console.log('File available at', downloadURL);
        });
      }
    );
    setImageUpload(newNamePhoto)
  }

  const getPoemes = (colRef)=>{
    // Option ou les données sont update en temps réel
    onSnapshot(colRef,(querySnapshot )=>{
      const poemesList = querySnapshot.docs.map(doc => ({...doc.data(), id:doc.id}));
      setPoemes(poemesList)
    })
  }

  const handleAllPoems = ()=>{
    getPoemes(orderAsc)
  }

  const handleSortYear = (y)=>{
    const sortYearList= query(poemesCollectionRef, where("annee", "==", y))
    getPoemes(sortYearList)
  }
  
  const handleSortCategories = (c)=>{
    const sortCategorieList= query(poemesCollectionRef, where("categorie", "==", c))
    getPoemes(sortCategorieList)
  }

  const handleSortTitle = (t)=>{
    const sortTitleList= query(poemesCollectionRef, where("title", "==", t))
    getPoemes(sortTitleList)
  }

  useEffect(()=>{
    // Afficher les années
    const getYears = async ()=>{
      const data = await getDocs(yearsCollectionRef);
      const yearsList = data.docs.map(doc => doc.data());
      setYears(yearsList)
    }

    const getCategories = async ()=>{
      const data = await getDocs(categoriesCollectionRef);
      const categorieList = data.docs.map(doc => doc.data());
      setCategories(categorieList)
    }

    getCategories()
    getYears()
    imageUpload && uploadFile()
    getPoemes(orderAsc)
    //desactiver eslint-disable  à cause des dependances manquantes dans le hook 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return(
    <>
      <div className="container-fluid poemPage">
        <PoemesPublic />
        <hr />
        <div className="row">
          
          <div className="col-lg-9">
                {
                    poemes.map((poeme)=>{
                        if(poeme.id !== values.poemeModifier ){
                        {/* Poeme public */}
                            return(
                                <div className="row justify-content-center poemes mb-4 pt-4" key={poeme.id}>
                                    <div className="col-lg-12 bg-light display-poemes">
                                        <div className="poeme">
                                          {/* <img src={Ligne} alt="Ligne Separateur" /> */}
                                          <h2>{poeme.title}</h2>
                                          {
                                              currentUser && 
                                              <h6>Ajouté par: {poeme.addBy}</h6>
                                          }
                                          <p>{poeme.text}</p>
                                          <p><i>{poeme.annee}</i></p>
                                        </div>
                                        <div className="btn-user">
                                            {
                                                currentUser && 
                                                <>
                                                    <Bouton btn="btn" click={(poemeModifier)=>{setValues({poemeModifier : poeme.id}) }}><i className="fa-solid fa-pen-to-square"></i></Bouton>
                                                    <Bouton btn="btn" click={()=>{deletePoeme(poeme.id)}}><i className="fa-solid fa-trash"></i></Bouton>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        }else{
                            {/* poeme a modifier */}
                            return(
                                <div className="row justify-content-center pt-4 " key={poeme.id}>
                                    <div className="col-lg-4">
                                        <img className='w-50' src={imageUpload} alt="" />
                                        <FormInput 
                                            label="Modification photo"
                                            type="file" 
                                            accept="image/*" 
                                            change = {(event)=>{setImageUpload(event.target.files[0])}}
                                        />
                                        <Bouton btn={"btn btn-success my-2"} click={uploadFile}>Valider</Bouton>
                                    </div>
                                    <div className="col-lg-6 poemes" >
                                        <FormInput 
                                            label = "Titre poème"
                                            defaultValue={poeme.title}  
                                            change = {(event)=>{
                                                setNewTitle(event.target.value)
                                            }}
                                        />
                                        <FormInput 
                                            label = "Année poème"
                                            type='number'
                                            defaultValue={poeme.annee}  
                                            change = {(event)=>{
                                                setNewAnnee(event.target.value)
                                            }}
                                        />
                                        <FormInput 
                                            label = "Poème ajouté par : "
                                            defaultValue={poeme.addBy}  
                                            change = {(event)=>{
                                                setNewAddBy(event.target.value)
                                            }}
                                        />
                                        <div className="form-group">
                                            <label htmlFor="text">Poème</label>
                                            <textarea 
                                                className='form-control'
                                                rows="10" 
                                                cols="33" 
                                                defaultValue={poeme.text}
                                                onChange={(event)=>{
                                                    setNewText(event.target.value)
                                                }}>
                                            </textarea>
                                        </div>
                                        <Bouton btn={"btn btn-success my-2"} click={()=>{updatePoeme(poeme.id)}}>Valider</Bouton>
                                        <Bouton btn={"btn btn-warning my-4 mx-2"}  click={()=>{setValues({form:false})}}>Annuler</Bouton>
                                    </div>
                                </div>
                            )
                        }
                    })
                }
          </div>
          <div className="col-lg-3">
              <div className="row sort">
                 
                  {/* Categorie annees */}
                  {/* {years[0].year && years[0].year.map((y, key)=>{
                      return (
                          <div key={key}>
                              <Bouton
                              click={()=>handleSortYear(y)}
                              value={y}>
                                  {y}
                              </Bouton>
                          </div>
                      )
                  })} */}
                  {/* Categorie theme */}
                  {/* {categories[0].categories && categories[0].categories.map((c, key)=>{
                      return (
                          <div key={key}>
                              <Bouton
                                  click={()=>handleSortCategories(c)}
                                  value={c}>
                                      {c}
                              </Bouton>
                          </div>
                      )
                  })} */}
                {/* Categorie poemes */}
                <div className="col-lg-12">
                <Bouton
                      click={handleAllPoems}>
                          <b>Tout</b>
                  </Bouton>
                {
                    poemes.map(poeme=>{
                        return(
                            <div key={poeme.id}>
                                <Bouton
                                    click={()=>handleSortTitle(poeme.title)}
                                    value={poeme.title}>
                                        {poeme.title}
                                </Bouton>
                            </div>
                        )
                    })
                }
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Poemes;
