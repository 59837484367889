import React, { useEffect } from 'react'
import { signOut } from "firebase/auth";
import {auth} from '../../firebase-connect'
import { AuthContext } from '../context/AuthContext';
import { useContext } from 'react';
import { useNavigate } from 'react-router';

export default function Logout() {
    const {dispatch} = useContext(AuthContext)
    const navigation = useNavigate()
    
    useEffect(()=>{
        signOut(auth).then(() => {
            // Sign-out successful.
            dispatch({type: "LOGOUT"})
            navigation("/")
            }).catch((error) => {
            // An error happened.
        });
    }, [navigation])

    return (
        <div>Logout</div>
    )
}
