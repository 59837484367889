import Photo2 from "../../assets/img/ill-francis-21.png"
import face from "../../assets/img/face.png"

export default function PoemesPublic(){
    return(
        <>
            <div className="row justify-content-center nav">
                <div className="col-lg-2">
                    <img src={Photo2} alt="Francis" />
                    <img className="ill face" src={face} alt="Silhouette" />
                    <h2>Les poemes du Francis</h2>
                </div>
                <div className="col-lg-5">
                    <p>
                        <i> Pour Francis les vers coulent tous seuls. Il ne s'assoie pas pour attendre la rime venir. Les lignes se remplissent et le poème est là. Francis adore lire, comme tous les grands poètes et ça on peut observer en le lisant. Son monde est vaste, je ne serais pas dire, ni où il commence, ni où il finit. 
                        <br/>Francis peut être romantique, philosophique, olfactif, auditif, insistant, botanique, ancestral, injurieux, modern, inattendu, précieux, ...
                        <br/><br/>Voyagez avec Francis! </i>
                    </p>
                </div>
            </div>
        </>
    )
}