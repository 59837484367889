import {NavLink} from "react-router-dom"
import logo from '../../assets/img/favicon.png'
import { useContext, useState } from "react"
import { AuthContext } from "../context/AuthContext"
import AmpouleGrand from "../../assets/img/ampoules-grand-citation.gif"
import Spider from "../../assets/img/spider-web.gif"

export default function Navbar(){
    const [url, setUrl] = useState("")
    const {currentUser} = useContext(AuthContext)

    return(
        <>
            <header >
                <div className="header-cover mx-5 mb-5">
                    <NavLink className="navbar-brand logo-desktop"  to="/">                       
                        <img className="logo" src={logo} alt="logo Francis" />
                    </NavLink>
                    <img className="bulb" src={AmpouleGrand} alt="ampoules" />
                    <NavLink className="navbar-brand logo-mobile mt-5"  to="/">                       
                        <img className="logo" src={logo} alt="logo Francis" />
                    </NavLink>
                </div>
                <nav className="navbar navbar-expand-lg navbar-dark justify-content-center bg-accent">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent " >
                    
                        <ul className="navbar-nav ">
                            <li className="nav-item ">
                                <NavLink className="nav-link" to="/collaborer-avec-francis" >                       
                                    Collaborer avec Francis
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/poemes"  >
                                    Poèmes
                                </NavLink>
                            </li>  
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/"  >
                                    Bienvenue chez Francis
                                </NavLink>
                            </li>              
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/les-citations-de-francis" >
                                    Francis est inspiré
                                </NavLink>
                            </li>  
                            {currentUser ? 
                                <>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/ajouter-poeme"  >
                                            Ajouter un poème
                                        </NavLink>
                                    </li>  
                                
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/ajouter-citation"  >
                                            Ajouter une citation
                                        </NavLink>
                                    </li>  
                                
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/logout"  >
                                            Deconnexion
                                        </NavLink>
                                    </li> 
                                </>
                            :                                 
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/login"  >
                                        Connexion
                                    </NavLink>
                                </li> 
                            }
                        </ul>
                    </div>
                </nav>
            </header>
           
        </>
    )
}