import React, { useContext } from 'react'
import {NavLink} from "react-router-dom"
import quote from "../../assets/img/citation.png"
import Photo4 from "../../assets/img/ill-francis-41.png"
import { useEffect, useState } from "react"
import { collection, onSnapshot, doc, deleteDoc, setDoc, Timestamp } from "firebase/firestore"; 
import {db} from "../../firebase-connect"
import { AuthContext } from '../../components/context/AuthContext';
import Bouton from '../Bouton/Bouton'

export default function Quotes(){
    const quotesRef = collection(db, "quotes");
    const [quotes, setQuote] = useState([])
    const currentUser = useContext(AuthContext)
    console.log(currentUser)
    const [currentQuote, setCurrentQuote] = useState("")
    const [text, setText] = useState("")
    const newDate = Timestamp.fromDate(new Date()).toDate()
    
    const getQuote = ()=>{
        onSnapshot(quotesRef, (querySnapshot) => {
            const quotes = querySnapshot.docs.map((doc) => 
                ({...doc.data(), id:doc.id})
            );
            setQuote(quotes)
        });
    }

    const handleDeleteQuote = async(id)=>{
        await deleteDoc(doc(db, "quotes", id));
    }

    const handleEditQuote = async(id) => {
        if(text !== ""){
            await setDoc(doc(db, "quotes", id), {
                text : text,
                dateAdd : newDate
              });
        }
        setCurrentQuote("")
    }

    useEffect(()=>{
        getQuote()
        // eslint-disable-next-line
    }, [])

    return(
        <>
            <div className="quotes">
                <div className="container-fluid nav">
                    <div className="row justify-content-center ">
                        <div className="col-lg-2">
                            <NavLink to="/les-citations-de-francis">
                                <img src={Photo4} alt="Francis" />
                                <img className="ill quote" src={quote} alt="Citation" />
                                <h2>Francis est inspiré</h2>
                            </NavLink>
                        </div>
                        <div className="col-lg-5">
                            <p>
                                <i>Francis a vécu sa vie pleinement… aujourd'hui il s'exprime en citations... :)) </i>
                            </p>
                            {
                                quotes.map((quote)=>{
                                    if(quote.id !== currentQuote){
                                        return(
                                            <div className='display-quotes' key={quote.id}>
                                                <p ><i  className="fa-solid fa-quote-left mx-2"></i>{quote.text}</p>
                                                <div className="btn-user">
                                                    {
                                                        currentUser === null && 
                                                        <>
                                                            <Bouton btn="btn" click={()=>{setCurrentQuote(quote.id)}}><i className="fa-solid fa-pen-to-square"></i></Bouton>
                                                            <Bouton btn="btn" click={()=>{handleDeleteQuote(quote.id)}}><i className="fa-solid fa-trash"></i></Bouton>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }else{
                                        return(
                                            <div className='editQuotes' key={quote.id}>
                                                <textarea className='form-control ' defaultValue={quote.text} name="quote" id="quote" cols="30" rows="10" onChange={(e)=>{setText(e.target.value)}}></textarea>
                                                <Bouton btn="btn btn-primary my-2" click={()=>{handleEditQuote(quote.id)}}>Valider</Bouton>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}