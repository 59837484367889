import React from 'react'

export default function FormInput(props){
    
    const {defaultValue, label, placeholder, type = "text", required,  change } = props
    return (
        <>
            <div className="form-group">
                <label>{label}</label>
                <input 
                    type={type}
                    className="form-control"
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    onChange={change}
                    required={required}
                />
            </div>
        </>
  )
}
