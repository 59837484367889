import { NavLink } from "react-router-dom";
import { useContext, useState } from "react";
import { auth } from "../../firebase-connect";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import panda from "../../assets/img/panda.png"
import Photo5 from "../../assets/img/ill-francis-51.png"

export default function Login(){
    const [error, setError] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    // Déclarer une variable pour redirect la page après connexion
    const navigate = useNavigate()
    const {dispatch} = useContext(AuthContext)

    const handleLogin = (e)=>{
        e.preventDefault()

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                dispatch({type: "LOGIN", payload : user })
                // Redirect vers la page poemes
                navigate("/poemes")
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage)
                setError(true)
            });

    }
      return(
        <>

            <div className="login">
                <div className="container-fluid nav">
                    <div className="row justify-content-center ">
                        <div className="col-lg-2">
                            <NavLink to="/poemes">
                                <img src={Photo5} alt="Francis" />
                                <img className="ill panda" src={panda} alt="Panda" />
                                <h2>Ajouter un poeme</h2>
                            </NavLink>
                        </div>
                        <div className="col-lg-5">
                            <p>
                                <i> Connectez-vous pour ajouter un poème de Francis. <br/>
                                    Si vous êtes son ami(e) et vous avez ses poèmes n'hesitez pas à demander l'accès. <br/>
                                    Ce site a le but de réunir tous ces créations.
                                </i>
                            </p>
                            <form onSubmit={handleLogin}>
                                <div className="form-group">
                                    <input type="email" className="form-control my-2" id="email" aria-describedby="emailHelp" placeholder="Email" onChange={(e)=>{setEmail(e.target.value)}}/>
                                </div>
                                <div className="form-group">
                                    <input type="password" className="form-control" id="password" placeholder="Password" onChange={(e)=>{setPassword(e.target.value)}}/>
                                </div>
                                <button type="submit" className="btn btn-primary my-2">Connexion</button>
                            {error &&  <p className="alert alert-danger">Le mot de passe ou l'email est erroné</p>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
      )
}