import {NavLink} from "react-router-dom"
import Photo3 from "../../assets/img/ill-francis-31.png"
import flower from "../../assets/img/fleur.png"

export default function Collaboration(){
    return(
        <>
            <div className="collaboration">
                <div className="container-fluid nav">
                    <div className="row justify-content-center ">
                        <div className="col-lg-2">
                            <NavLink to="/collaborer-avec-francis">
                                <img src={Photo3} alt="Francis" />
                                <img className="ill flower" src={flower} alt="Fleur" />
                                <h2>Collaborer avec Francis</h2>
                            </NavLink>
                        </div>
                        <div className="col-lg-5">
                            <p><i>
                                Vous avez besoin de l'inspiration pour votre marque, Francis est là pour apporter une touche d'originalité … Besoin d'une collaboration n'hésitez pas à envoyer un e-mail: collaboration@francis-lepoete.fr
                            </i></p>
                        </div>
                        
                    </div>
                </div>
                {/* <div className="signature">
                    <img className="mx-5" src={Sun} alt="Fleur" />
                </div> */}
            </div>
        </>
    )
}