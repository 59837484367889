import React from 'react'
import panda from "../../assets/img/panda.png"
import sign from "../../assets/img/sign.png"
import Photo5 from "../../assets/img/ill-francis-51.png"
import { NavLink } from 'react-router-dom'
import { useState, useContext } from 'react'
import { auth } from "../../firebase-connect";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { AuthContext } from '../context/AuthContext'
import {useNavigate} from "react-router-dom"

export default function SignUp() {
    const [validation, setValidation] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const {dispatch} = useContext(AuthContext)
    const navigate = useNavigate()

    const hundleSignUp = (e) => {
        e.preventDefault()

        if(password !== passwordConfirm){
            setValidation("Les mots de passe de coincident pas!")
            return
        }
        if((password.length || passwordConfirm.length) <6){
            setValidation("Le mot de passe doit contenir min 6 caractères")
            return
        }

        
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                dispatch({type: "SIGN UP", payload : user })
                navigate("/login")
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
                if(error.code === "auth/invalid-email"){
                    setValidation("Le format de l'email n'est pas valide.")
                }
                if(error.code === "auth/email-already-in-use"){
                    setValidation("Cet e-mail a été déjà utilisé.")
                }
            });
        }
  return (
    <>
        <div className="sign-up">
                <div className="container-fluid nav">
                    <div className="row justify-content-center ">
                        <div className="col-lg-2">
                            <NavLink to="/poemes">
                                <img src={Photo5} alt="Francis" />
                                <img className="ill panda" src={panda} alt="Panda" />
                                <h2>Authentification</h2>
                            </NavLink>
                        </div>
                        <div className="col-lg-5">
                            <p>
                                <i> Aliquam id libero orci. Nam sodales erat felis, vel pretium ex ornare id. Duis venenatis suscipit arcu, eget pulvinar nunc euismod vitae. Etiam dictum laoreet justo. Praesent condimentum rutrum turpis efficitur ultricies. Nunc egestas, odio quis ullamcorper efficitur, sem arcu gravida quam, sed consectetur mi nunc gravida eros. Nam vel metus sed felis convallis facilisis. Integer dictum luctus diam vitae blandit. </i>
                            </p>
                            <form onSubmit={hundleSignUp}>
                                <div className="form-group">
                                    <input type="email" className="form-control my-1" id="email" aria-describedby="emailHelp" placeholder="Email" onChange={(e)=>{setEmail(e.target.value)}}/>
                                </div>
                                <div className="form-group">
                                    <input type="password" required className="form-control" id="password" placeholder="Mot de passe" onChange={(e)=>{setPassword(e.target.value)}}/>
                                </div>
                                <div className="form-group">
                                    <input type="password" required className="form-control my-1" id="password-confirm" placeholder="Confirmer le mot de passe" onChange={(e)=>{setPasswordConfirm(e.target.value)}}/>
                                </div>
                                <button type="submit" className="btn btn-primary my-1">Connexion</button>
                            {validation &&  <p className="alert alert-danger">{validation}</p>}
                            </form>
                        </div>
                    </div>
                </div>
                <div className="signature">
                    <img className="" src={sign} alt="Signature" />
                </div>
                
            </div>
    </>
  )
}
